
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { AuthService } from '../services/auth-service';
import BasicHelpDialog from '@/components/BasicHelpDialog.vue';
import { SetSnackBar } from '@/helpers/SnackbarHelper';

const namespace: string = 'profile';

@Component({
  components: { BasicHelpDialog }
})
export default class LoginComponent extends Vue {
  $refs!: {
    helpDeskDialog: HTMLFormElement;
  };
  UserName: string = '';

  EmailRules: any[] = [
    (v: any) => !!v || 'E-mail is required',
    (v: any) => /.+@.+\..+/.test(v) || v == 'admin' || 'E-mail must be valid'
  ];
  isLoading: boolean = false;
  shouldShowPasswordReset: boolean = false;
  password: string = '';
  confirmPassword: string = '';
  didSendEmailSuccessfully: boolean = false;
  isPasswordResetValid: boolean = false;
  arePasswordsValid: boolean = false;
  hasTriedCreatingUserAccount: boolean = false;
  async SendPasswordResetEmail() {
    const sendGenericError = () => {
      SetSnackBar(
        'There was an error resetting your password, please contact techsupport@just1label.com'
      );
    };
    this.isLoading = true;
    try {
      await AuthService.SendPasswordResetEmail(this.UserName);
      this.didSendEmailSuccessfully = true;
    } catch (err) {
      sendGenericError();
    }
    this.isLoading = false;
  }
  async ChangePassword() {
    this.isLoading = true;
    try {
      var model = {
        Password: this.password,
        ConfirmPassword: this.confirmPassword,
        Vcode: this.$route.params.vcode,
        Code: this.$route.params.code
      };
      AuthService.resetPasswordWithToken(model);
      this.arePasswordsValid = false;
      SetSnackBar('Password Changed Successfully!');
      setTimeout(() => {
        this.goTo('/login');
      }, 1000);
    } catch (err) {
      SetSnackBar(
        'There was an error changing your password, please contact techsupport@just1label.com'
      );
    }
    this.isLoading = false;
  }
  goToSignUp(): void {
    this.$router.push('/Register');
  }
  goTo(path: string): void {
    this.$router.push(path);
  }
  goToAmazonTransparency() {
    window.location.href = 'https://www.just1label.com/transparency';
  }
  mounted() {
    console.log(this.$route.params.code);
    console.log(this.$route.params.vcode);
    if (this.$route.params.code && this.$route.params.vcode)
      this.shouldShowPasswordReset = true;
  }

  get passwordConfirmationRule() {
    return this.password === this.confirmPassword || 'Password must match';
  }
}
